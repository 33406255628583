.VisitTheSetSection{
    background: url('../../assets/imgs/visit.gif');
    background-size: cover;
    margin-top: 100px;
    display: inline-block;
    width: 100%;
}
.VisitTheSetHeading {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 40px 0;
    gap: 40px 0;
    background: radial-gradient(139.76% 301.04% at -3.41% -2.35%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.79) 0.01%, rgba(0, 0, 0, 0.35) 100%);
    mix-blend-mode: normal;
    box-shadow: 0px 0px 19px 14px rgb(254 175 32 / 10%);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    margin: 0 auto;
    max-width: max-content;
    padding: 50px;
    margin: 15% auto;
}
.VisitTheSetHeading h2 {
    font-size: 140px;
    line-height: 116px;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #865E17;
    color: #FEAF20;
    text-align: center;
    text-shadow: 0 0 50px #00000080;
}
.visitSetInfoPage {
    font-size: 14px;
    margin: 0;
    line-height: normal;
    color: #fff;
}
.disabledBtn{
    cursor: not-allowed;
}
@media (min-width:768px) and (max-width:1024px){
    .VisitTheSetHeading h2 {
        font-size: 82px;
        line-height: normal;
    }
    .VisitTheSetHeading {
        height: 500px;
    }
    .VisitTheSetSection {
        margin-top: 50px;
    }
}
@media (max-width:767px){
    .VisitTheSetHeading h2 {
        font-size: 52px;
        line-height: normal;
    }
    .VisitTheSetHeading {
        height: 500px;
    }
    .VisitTheSetSection {
        margin-top: 50px;
    }
}