@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0F0F0F;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bodyWrap.fixedBodyBg{
  background-image:url('./assets/imgs/bodyPattern.jpg');
  background-position: top center;
  background-attachment: fixed;
  background-size: 100% 100%;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}


@font-face {
  font-family: "Futura Heavy";
  src: local("Futura"),
    url("./assets/fonts/Futura/futura-bold.TTF") format("truetype");
}
@font-face {
  font-family: "Futura";
  src: local("Futura"),
    url("./assets/fonts/Futura/futura-bold.OTF") format("opentype");
}


.FuturaFont{
  font-family: "Futura";
}
body p{
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
h2 {
  font-size: 38px;
  line-height: 36px;
  font-family: 'Futura';
  text-transform: uppercase;
  color: #000;
  margin: 0;
  font-weight: 700;
}

@media (min-width:1600px) and (max-width:1919px){
  section.bannerGrid {
      zoom: 0.844;
  }
}
@media (min-width:1440px) and (max-width:1599px){
  section.bannerGrid {
      zoom: 80%;
      /* zoom: 0.7577; */
      
  }
}
@media (min-width:1300px) and (max-width:1430px) {
  section.bannerGrid .translatedGrid {
      zoom: 0.72;
  }
  .aboutSection {
      padding: 100px 0 0;
  }
  section.featureFilm {
      padding: 100px 0 60px;
  }
  section.LandingSections {
      padding: 61px 0 120px !important;
  }
  .contentCol button.siteButton.mintButton,
  .FlinchMerchContent .siteButton.mintButton {
      font-size: 20px !important;
  }
  section.TheCrewSection {
      padding: 150px 0 100px !important;
      text-align: center;
  }
  footer.footer {
      padding: 50px 0;
  }
  .CrewMember h3{
    font-size: 26px;
  }
}
@media (min-width:1024px) and (max-width:1299px) {
  .container {
      max-width: 100%;
      margin: 0 auto;
      padding: 0 15px;
  }
  section.bannerGrid .translatedGrid .translatedBlock:nth-child(2), section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
      width: 578px !important;
      max-width: 578px !important;
      min-width: 578px !important;
      clip-path: polygon(145px 0, 100% 0%, 436px 100%, 0% 100%);
  }
  section.bannerGrid .translatedGrid {
      zoom: 0.55;
  }
  .aboutSection {
      padding: 100px 0 0;
  }
  section.featureFilm {
      padding: 100px 0 60px;
  }
  section.LandingSections {
      padding: 61px 0 120px !important;
  }
  .contentCol button.siteButton.mintButton,
  .FlinchMerchContent .siteButton.mintButton {
      font-size: 20px !important;
  }
  section.TheCrewSection {
      padding: 150px 0 100px !important;
      text-align: center;
  }
  footer.footer {
      padding: 50px 0;
  }
  .CrewMember h3{
    font-size: 26px;
  }
  .footerCols {
      display: flex;
      justify-content: space-between;
      gap: 0 !important;
  }
}

@media (max-width:768px) and (max-width:1024px) {
  .container {
      padding: 0 15px;
  }
  h2{
    font-size: 36px;
  }
  body p {
      font-size: 16px;
      line-height: 32px;
    }
    
}
@media (max-width:767px) {
  .container {
      padding: 0 15px;
  }
  h2{
    font-size: 32px;
  }
  body p {
      font-size: 14px;
      line-height: 26px;
    }
    
}

@media (max-width:320px){
  h2 {
      font-size: 26px;
  }
}