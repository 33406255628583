.productBlock{
    padding: 12.64px;
    background: linear-gradient(135deg, rgba(254, 175, 32, 0) 0%, rgba(254, 175, 32, 0.3) 47.92%, rgba(254, 175, 32, 0) 100%);
}
.FlinchMerchContent h2 {
    text-align: center;
    color: #FEAF20;
    margin-bottom: 64px;
}
.productGrid {
    display: flex;
    gap: 24px;
    margin-bottom: 88px;
}
.productBlock img {
    width: 100%;
}
.FlinchMerchContent .siteButton.mintButton {
    font-size: 26px;
    width: 195px;
    height: 68px;
}
@media (min-width:768px) and (max-width:1024px){
    section.FlinchMerchSection {
        padding: 60px 0 0;
    }
    .FlinchMerchContent h2 {
        text-align: center;
        color: #FEAF20;
        margin-bottom: 20px;
    }
    .FlinchMerchContent .siteButton.mintButton {
        font-size: 20px;
        width: 165px;
        height: 58px;
    }
    section.FlinchMerchSection .productGrid {
        margin-bottom: 30px;
    }
}
@media (max-width:767px){
    section.FlinchMerchSection {
        padding: 60px 0 0;
    }
    section.FlinchMerchSection .productGrid {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .FlinchMerchContent h2 {
        text-align: center;
        color: #FEAF20;
        margin-bottom: 20px;
    }
    .FlinchMerchContent .siteButton.mintButton {
        font-size: 20px;
        width: 165px;
        height: 58px;
    }
}