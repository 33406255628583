.HeadingPattern {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../assets/imgs/headingpatterns.jpg');
    background-size: contain;
    height: 165px;
    background-position: center;
    box-shadow: 0px 0px 45px rgb(254 175 32 / 40%);
}
.HeadingPattern h2{
    margin: 0;
}
.VideoContent {
    border: 5.01px solid #fdae1e;
    margin-bottom: 40px;
}
section.VideoSection p {
    text-align: center;
    color: #fff;
    max-width: 1017px;
    margin: 0 auto;
}
section.VideoSection {
    padding: 58px 0 0;
}
section.featureFilm {
    padding: 50px 0 160px;
}
.featureFilmContent {
    max-width: 714px;
    margin: 0 auto;
}
.featureFilmContent .featureFilmHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.featureFilmContent .featureFilmHeader {
    display: flex;
    justify-content: space-between;
}
.featureFilmContent .featureFilmHeader h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
}
.featureFilmContent .featureFilmHeader .siteButton {
    width: 199px;
    padding: 0;
    font-size: 26px;
}

.featureFilmContent .filmMintArea .notice {
    height: 52px;
    background: #27FCFA;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    margin: 32px 0 40px;
    font-weight: 500;
}

@media (min-width:768px) and (max-width:1024px){
    .VideoContent .vimeoEmbed {
        height: 410px !important;
    }
    section.VideoSection .HeadingPattern {
        padding: 20px 0 0;
        height: 100px;
    }
    section.featureFilm {
        padding: 60px 0 0;
    }
}
@media (min-width:991px) and (max-width:1199px){
    .VideoContent .vimeoEmbed {
        height: 560px !important;
    }
}
@media (max-width:767px){
    section.VideoSection .vimeoEmbed {
        height: 226px !important;
    }
    .HeadingPattern {
        height: 115px;
        padding-top: 20px;
    }
    .featureFilmContent .featureFilmHeader {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .featureFilmContent .featureFilmHeader h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .featureFilmContent .filmMintArea .notice {
        padding: 15px;
    }
    section.featureFilm {
        padding: 60px 0 20px;
    }
}