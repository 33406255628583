.HeadingPattern.contentHeaderPattern .contentHeader {
    min-width: 370px;
}
.visitSetSec .HeadingPattern.contentHeaderPattern {
    justify-content: start;
    padding: 0 44px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
}
.thumbnailCol img{
    width: 532px;
    height: 532px;
    object-fit: cover;
}
.contentGrid {
    display: flex;
    padding: 0 44px;
    gap: 32px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
    margin-top: -140px;
}
.contentCol p {
    font-size: 18px;
    color: #fff;
    margin: 30px 0 44px;
}
.contentCol .siteButton.mintButton {
    margin-left: 0;
    font-size: 26px;
    height: 68px;
    width: 241px;
    font-weight: 700;
}
section.LandingSections {
    padding: 61px 0 420px;
}
@media (min-width:768px) and (max-width:1024px){
    .contentGrid {
        flex-direction: row;
        padding: 0 15px;
    }
    .thumbnailCol, .thumbnailCol img {
        width: 100%;
        height: auto;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern {
        padding: 15px 15px 0;
        height: 100px;
    }
    section.LandingSections.visitSetSec .HeadingPattern.contentHeaderPattern{
        justify-content: start;
        
    }
    .HeadingPattern.contentHeaderPattern .contentHeader {
        display: inherit;
        min-width: 50%;
    }
    .thumbnailCol {
        width: auto !important;
        margin: -95px auto 0;
        height: max-content !important;
    }
    .contentCol p {
        margin: 0 0 30px;
    }
    section.LandingSections {
        padding: 60px 0 0px;
    }
    .LandingSections.visitSetSec .contentGrid {
        flex-direction: row;
    }
    .contentCol .siteButton.mintButton {
        margin-left: 0;
        font-size: 18px !important;
        height: 58px !important;
        width: 190px !important;
        font-weight: 700;
    }
    .contentCol {
        max-width: 40% !important;
    }
    
}
@media (max-width:767px){
    .contentGrid {
        flex-direction: column;
        padding: 0 15px;
    }
    .thumbnailCol, .thumbnailCol img {
        width: 100%;
        height: auto;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern .contentHeader {
        display: none;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern {
        padding: 0;
        justify-content: center;
    }
    section.LandingSections .HeadingPattern br {
        display: none;
    }
    .thumbnailCol {
        width: calc(100% - 42px);
        margin: -30px auto 0;
    }
    .contentCol p {
        margin: 0 0 30px;
    }
    section.LandingSections {
        padding: 60px 0 0px;
    }
    .LandingSections.visitSetSec .contentGrid {
        flex-direction: column-reverse;
    }
    .contentCol .siteButton.mintButton {
        margin-left: 0;
        font-size: 18px !important;
        height: 58px !important;
        width: 190px !important;
        font-weight: 700;
    }
    
}