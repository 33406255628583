.AboutContent{
    text-align: center;
}
.AboutContent h2 {
    color: #FEAF20;
}
.aboutSection{
    padding: 100px 0;
}
.AboutContent p {
    color: #fff;
}
.AboutContent {
    text-align: center;
    max-width: 945px;
    margin: 0 auto;
}
@media (min-width:768px) and (max-width:1024px){
    .aboutSection {
        padding: 60px 0 0;
    }
}
@media (max-width:767px){
    .aboutSection {
        padding: 60px 0 0;
    }
}