@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0F0F0F;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bodyWrap.fixedBodyBg{
  background-image:url(/static/media/bodyPattern.5503cf19.jpg);
  background-position: top center;
  background-attachment: fixed;
  background-size: 100% 100%;
}
.container {
  max-width: 1170px;
  margin: 0 auto;
}


@font-face {
  font-family: "Futura Heavy";
  src: local("Futura"),
    url(/static/media/futura-bold.74e5249c.TTF) format("truetype");
}
@font-face {
  font-family: "Futura";
  src: local("Futura"),
    url(/static/media/futura-bold.a572858f.OTF) format("opentype");
}


.FuturaFont{
  font-family: "Futura";
}
body p{
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
h2 {
  font-size: 38px;
  line-height: 36px;
  font-family: 'Futura';
  text-transform: uppercase;
  color: #000;
  margin: 0;
  font-weight: 700;
}

@media (min-width:1600px) and (max-width:1919px){
  section.bannerGrid {
      zoom: 0.844;
  }
}
@media (min-width:1440px) and (max-width:1599px){
  section.bannerGrid {
      zoom: 80%;
      /* zoom: 0.7577; */
      
  }
}
@media (min-width:1300px) and (max-width:1430px) {
  section.bannerGrid .translatedGrid {
      zoom: 0.72;
  }
  .aboutSection {
      padding: 100px 0 0;
  }
  section.featureFilm {
      padding: 100px 0 60px;
  }
  section.LandingSections {
      padding: 61px 0 120px !important;
  }
  .contentCol button.siteButton.mintButton,
  .FlinchMerchContent .siteButton.mintButton {
      font-size: 20px !important;
  }
  section.TheCrewSection {
      padding: 150px 0 100px !important;
      text-align: center;
  }
  footer.footer {
      padding: 50px 0;
  }
  .CrewMember h3{
    font-size: 26px;
  }
}
@media (min-width:1024px) and (max-width:1299px) {
  .container {
      max-width: 100%;
      margin: 0 auto;
      padding: 0 15px;
  }
  section.bannerGrid .translatedGrid .translatedBlock:nth-child(2), section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
      width: 578px !important;
      max-width: 578px !important;
      min-width: 578px !important;
      -webkit-clip-path: polygon(145px 0, 100% 0%, 436px 100%, 0% 100%);
              clip-path: polygon(145px 0, 100% 0%, 436px 100%, 0% 100%);
  }
  section.bannerGrid .translatedGrid {
      zoom: 0.55;
  }
  .aboutSection {
      padding: 100px 0 0;
  }
  section.featureFilm {
      padding: 100px 0 60px;
  }
  section.LandingSections {
      padding: 61px 0 120px !important;
  }
  .contentCol button.siteButton.mintButton,
  .FlinchMerchContent .siteButton.mintButton {
      font-size: 20px !important;
  }
  section.TheCrewSection {
      padding: 150px 0 100px !important;
      text-align: center;
  }
  footer.footer {
      padding: 50px 0;
  }
  .CrewMember h3{
    font-size: 26px;
  }
  .footerCols {
      display: flex;
      justify-content: space-between;
      grid-gap: 0 !important;
      gap: 0 !important;
  }
}

@media (max-width:768px) and (max-width:1024px) {
  .container {
      padding: 0 15px;
  }
  h2{
    font-size: 36px;
  }
  body p {
      font-size: 16px;
      line-height: 32px;
    }
    
}
@media (max-width:767px) {
  .container {
      padding: 0 15px;
  }
  h2{
    font-size: 32px;
  }
  body p {
      font-size: 14px;
      line-height: 26px;
    }
    
}

@media (max-width:320px){
  h2 {
      font-size: 26px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.headerWrap {
    display: flex;
    justify-content: space-between;
}
.headerWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    border-bottom: 2px solid #fff3;
}
.logoWrap a {
    display: inline-block;
    width: 100%;
}
.logoWrap a img {
    max-height: 32px;
}
.siteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 217px;
    height: 60px;
    border: 3px solid #FEAF20;
    -webkit-filter: drop-shadow(0px 0px 24px #FEAF20);
            filter: drop-shadow(0px 0px 24px #FEAF20);
    border-radius: 8px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-transform: uppercase;
    background: transparent;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: "Futura";
    margin: 0 auto;
    text-decoration: none;
}
.siteButton:hover, .siteButton:active {
    background: #FEAF20;
    -webkit-filter: drop-shadow(0px 0px 10px #FEAF20);
    filter: drop-shadow(0px 0px 10px #FEAF20);
}
.siteButton.mintButton {
    border: 3px solid #27fcfa;
    -webkit-filter: drop-shadow(0px 0px 24px #27fcfa);
            filter: drop-shadow(0px 0px 24px #27fcfa);
    width: 186px;
    padding: 0;
}
.siteButton.mintButton:hover,
.siteButton.mintButton:active{
    background: #27fcfa;
    -webkit-filter: drop-shadow(0px 0px 10px #27fcfa);
    filter: drop-shadow(0px 0px 10px #27fcfa);
}
.navWrap button {
    background: transparent;
    color: #FEAF20;
    font-size: 28px;
    border: none;
    cursor: pointer;
    padding: 0;
}
.headerWrap .navWrap, .headerWrap .ctaWrap {
    min-width: 217px;
}
.navWrap button:hover {
    color: #fff;
}
.socialList {
    justify-content: center;
    display: flex;
    align-items: center;
    grid-gap: 22px;
    grid-gap: 22px;
    gap: 22px;
    margin-top: 20px;
}
.socialList .socialConnect {
    background: transparent;
    border: none;
    color: #27FCFA;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 30px;
    width: 28px;
    cursor: pointer;
    position: relative;
}
.titleSocial {
    position: absolute;
    right: 23px;
    font-size: 11px;
    bottom: 14px;
    border: 1px solid #27FCFA;
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 100%;
    color: #000;
    background: #27FCFA;
    font-weight: 800;
}
.slideMenu {
    background: #0F0F0F;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999;
    max-width: 290px;
    padding: 60px 27px 0 37px;
    width: 100%;
    left: -354px;
    transition: all 0.4s linear;
    opacity: 0;
    border-right: 2px solid #fff3;
}
.menuActiveOverlay:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background: #0000006e;
    left: 0;
    top: 0;
    z-index: 9;
}
.slideMenu.menuActive{
    left: 0;
    opacity: 1;
}
ul.SideNavigation {
    padding: 52px 0;
    margin: 28px 0;
    border-top: 2px solid #fff3;
    border-bottom: 2px solid #fff3;
}
ul.SideNavigation li a.socialConnect {
    color: #FEAF20;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    position: relative;
}
ul.SideNavigation li a.socialConnect:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: #feaf20cc;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    clear: both;
    position: absolute;
    bottom: 0;
    left: 0;
}
ul.SideNavigation li a.socialConnect:hover:after,
ul.SideNavigation li a.socialConnect:visited:after{
    width: 50px;
}
ul.SideNavigation li:not(:last-child) a.socialConnect {
    margin-bottom: 56px;
}
.slideMenu .navWrap {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: visible;
    padding-bottom: 150px;
}
.slideMenu .navWrap .socialList {
    flex-wrap: wrap;
    justify-content: start;
    grid-gap: 26px 27px;
    grid-gap: 26px 27px;
    gap: 26px 27px;
}

.slideMenu .navWrap .socialList span.titleSocial {
    right: -10px;
}
@media (min-width:768px) and (max-width:1024px){
    .headerWrap .ctaWrap, .headerWrap .navWrap {
        min-width: unset;
    }
    .headerWrap {
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 30px 0px;
        gap: 30px 0px;
        position: relative;
    }
    .headerWrap .navWrap {
        order: 1;
    }
    .headerWrap .ctaWrap {
        order: 2;
        margin-left: 25px;
    }
    .headerWrap .logoWrap {
        width: auto;
        text-align: left;
        margin: 0 auto 0 0;
    }
    .headerWrap .logoWrap img {
        width: 100%;
        max-height: 32px;
        width: auto;
    }
    .headerWrap {
        flex-wrap: nowrap;
        justify-content: center;
        grid-gap: 30px 0px;
        gap: 30px 0px;
        position: relative;
    }
    ul.SideNavigation li:not(:last-child) a.socialConnect {
        margin-bottom: 20px;
    }
    ul.SideNavigation {
        padding: 35px 0;
        margin: 25px 0;
        border-top: 2px solid #fff3;
        border-bottom: 2px solid #fff3;
    }
}
@media (max-width:767px){
    .headerWrap .ctaWrap, .headerWrap .navWrap {
        min-width: unset;
    }
    .headerWrap {
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 30px 0px;
        gap: 30px 0px;
        position: relative;
    }
    .headerWrap .navWrap {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        left: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        margin-top: 40px;
    }
    .headerWrap .logoWrap {
        width: calc(100% - 100px);
        text-align: center;
        margin: 0 auto;
    }
    .logoWrap a img {
        max-height: 32px;
        width: 100%;
    }
    .headerWrap .logoWrap img {
        width: 100%;
    }
    ul.SideNavigation li:not(:last-child) a.socialConnect {
        margin-bottom: 10px;
    }
    ul.SideNavigation {
        padding: 25px 0;
        margin: 15px 0;
        border-top: 2px solid #fff3;
        border-bottom: 2px solid #fff3;
    }
}
section.bannerGrid .translatedGrid {
    display: flex;
    overflow: hidden;
    background-color: #000;
    max-width: 1900px;
    margin: 20px auto 0;
    justify-content: center;
}
section.bannerGrid .translatedGrid .translatedBlock {
    max-height: 600px;
    position: relative;
    overflow: hidden;
    width: 476px !important;
    max-width: 476px !important;
    min-width: 476px !important;
}
section.bannerGrid .translatedGrid .translatedBlock .gridImage {
    width: 100%;
    min-height: 600px;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
    position: relative;
    z-index: 1;
}
section.bannerGrid .translatedGrid .translatedBlock .gridButton {
    border: none;
    padding: 0;
    background: transparent;
    display: inline-block;
    width: 100%;
    overflow: visible;
    cursor: pointer;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(1) {
    -webkit-clip-path: polygon(0px 0, 100% 0%, 335px 100%, 0% 100%);
            clip-path: polygon(0px 0, 100% 0%, 335px 100%, 0% 100%);
    z-index: 4;
    margin-right: -135px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(2) {
    -webkit-clip-path: polygon(145px 0, 100% 0%, 330px 100%, 0% 100%);
            clip-path: polygon(145px 0, 100% 0%, 330px 100%, 0% 100%);
    z-index: 3;
    margin-right: -135px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
    -webkit-clip-path: polygon(145px 0, 100% 0%, 330px 100%, 0% 100%);
            clip-path: polygon(145px 0, 100% 0%, 330px 100%, 0% 100%);
    z-index: 2;
    margin-right: -135px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(4) {
    -webkit-clip-path: polygon(145px 0, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(145px 0, 100% 0%, 100% 100%, 0% 100%);
    z-index: 1;
    margin-right: 0;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(2),
section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
    width: 678px !important;
    max-width: 678px !important;
    min-width: 678px !important;
    -webkit-clip-path: polygon(145px 0, 100% 0%, 536px 100%, 0% 100%);
            clip-path: polygon(145px 0, 100% 0%, 536px 100%, 0% 100%);
}
section.bannerGrid .translatedGrid .translatedBlock img {
    width: 100%;
    min-height: 600px;
    object-fit: cover;
    height: 600px;
}
section.bannerGrid .translatedGrid .translatedBlock .gridContent {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 23px 0;
    bottom: 0;
    height: 240px;
    background: linear-gradient(180deg, rgba(254, 175, 32, 0) 0%, rgba(254, 175, 32, 0.8) 120%);
    width: 384px;
    transition: all 0.5s linear;
}
section.bannerGrid .translatedGrid .translatedBlock .gridContent h3 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    font-family: "Futura";
    text-transform: uppercase;
    color: #000;
}


section.bannerGrid .translatedGrid .translatedBlock:nth-child(1) .gridContent {
    width: 383px;
    padding-right: 164px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(2) .gridContent {
    width: 488px;
    padding-right: 132px;
    padding-left: 30px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) .gridContent {
    width: 488px;
    padding-left: 20px;
    padding-right: 149px;
}
section.bannerGrid .translatedGrid .translatedBlock:nth-child(4) .gridContent {
    width: 100%;
    padding-left: 30px;
}
section.bannerGrid .translatedGrid .translatedBlock .gridButton:hover .gridContent{
    bottom: -100%;
}
section.bannerGrid .translatedGrid .translatedBlock .gridButton:hover .gridImage{
    opacity: 0;
}
section.bannerGrid .translatedGrid .translatedBlock .gridButton .gridGif img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: auto;
    object-fit: unset;
    left: 0;
    background: #000;
    right: 0;
    bottom: 0px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
section.bannerGrid .translatedGrid .translatedBlock .gridButton:hover .gridGif img{
    opacity: 1;
}
section.bannerGrid .translatedGrid .translatedBlock .gridGif img {
    width: 100%;
    object-fit: cover !important;
}
@media (min-width:768px) and (max-width:1024px){
    section.bannerGrid .translatedGrid {
        flex-wrap: wrap;
        overflow-x: hidden;
        zoom: 1;
        justify-content: start;
        width: 100% !important;
        max-width: unset !important;
    }
    section.bannerGrid .translatedGrid .translatedBlock,
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(2),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
        -webkit-clip-path: unset !important;
                clip-path: unset !important;
        margin: 0 !important;
        width: calc(50% - 2.5px) !important;
        max-width: calc(50% - 2.5px) !important;
        min-width: calc(50% - 2.5px) !important;
        max-height: 550px;
    }
    section.bannerGrid .translatedGrid .translatedBlock .gridContent {
        margin: 0 !important;
        width: 100% !important;
        padding: 0 0 20px !important;
        left: 0 !important;
    }
    section.bannerGrid .translatedGrid .translatedBlock .gridContent h3 {
        font-size: 24px;
        line-height: 26px;
    }
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(1),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(3){
        border-right: 2.5px solid #27FCFA;
    }
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(1),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(2){
        border-bottom: 4px solid #27FCFA;
    }
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(2),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(4){
        border-left: 2.5px solid #27FCFA;
    }
}
@media (max-width:767px){
    section.bannerGrid .translatedGrid {
        flex-wrap: wrap;
        overflow-x: scroll;
        zoom: 1;
        justify-content: start;
        width: 100% !important;
        max-width: unset !important;
    }
    section.bannerGrid .translatedGrid .translatedBlock,
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(2),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(3) {
        -webkit-clip-path: unset !important;
                clip-path: unset !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        max-height: 450px;
    }
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(1),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(2),
    section.bannerGrid .translatedGrid .translatedBlock:nth-child(3){
        border-bottom: 4px solid #27FCFA;
    }
    section.bannerGrid .translatedGrid .translatedBlock .gridContent {
        margin: 0 !important;
        width: 100% !important;
        padding: 0 0 20px !important;
        left: 0 !important;
    }
    section.bannerGrid .translatedGrid .translatedBlock .gridContent h3 {
        font-size: 24px;
        line-height: 26px;
    }
}
.AboutContent{
    text-align: center;
}
.AboutContent h2 {
    color: #FEAF20;
}
.aboutSection{
    padding: 100px 0;
}
.AboutContent p {
    color: #fff;
}
.AboutContent {
    text-align: center;
    max-width: 945px;
    margin: 0 auto;
}
@media (min-width:768px) and (max-width:1024px){
    .aboutSection {
        padding: 60px 0 0;
    }
}
@media (max-width:767px){
    .aboutSection {
        padding: 60px 0 0;
    }
}
.HeadingPattern {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/static/media/headingpatterns.4306d981.jpg);
    background-size: contain;
    height: 165px;
    background-position: center;
    box-shadow: 0px 0px 45px rgb(254 175 32 / 40%);
}
.HeadingPattern h2{
    margin: 0;
}
.VideoContent {
    border: 5.01px solid #fdae1e;
    margin-bottom: 40px;
}
section.VideoSection p {
    text-align: center;
    color: #fff;
    max-width: 1017px;
    margin: 0 auto;
}
section.VideoSection {
    padding: 58px 0 0;
}
section.featureFilm {
    padding: 50px 0 160px;
}
.featureFilmContent {
    max-width: 714px;
    margin: 0 auto;
}
.featureFilmContent .featureFilmHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.featureFilmContent .featureFilmHeader {
    display: flex;
    justify-content: space-between;
}
.featureFilmContent .featureFilmHeader h2 {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
}
.featureFilmContent .featureFilmHeader .siteButton {
    width: 199px;
    padding: 0;
    font-size: 26px;
}

.featureFilmContent .filmMintArea .notice {
    height: 52px;
    background: #27FCFA;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    margin: 32px 0 40px;
    font-weight: 500;
}

@media (min-width:768px) and (max-width:1024px){
    .VideoContent .vimeoEmbed {
        height: 410px !important;
    }
    section.VideoSection .HeadingPattern {
        padding: 20px 0 0;
        height: 100px;
    }
    section.featureFilm {
        padding: 60px 0 0;
    }
}
@media (min-width:991px) and (max-width:1199px){
    .VideoContent .vimeoEmbed {
        height: 560px !important;
    }
}
@media (max-width:767px){
    section.VideoSection .vimeoEmbed {
        height: 226px !important;
    }
    .HeadingPattern {
        height: 115px;
        padding-top: 20px;
    }
    .featureFilmContent .featureFilmHeader {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .featureFilmContent .featureFilmHeader h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .featureFilmContent .filmMintArea .notice {
        padding: 15px;
    }
    section.featureFilm {
        padding: 60px 0 20px;
    }
}
.HeadingPattern.contentHeaderPattern .contentHeader {
    min-width: 370px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
}
.thumbnailCol img{
    width: 532px;
    height: 532px;
    object-fit: cover;
}
.contentGrid {
    display: flex;
    padding: 0 44px;
    grid-gap: 32px;
    gap: 32px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
    margin-top: -140px;
}
.contentCol p {
    font-size: 18px;
    color: #fff;
    margin: 30px 0 44px;
}
.contentCol button.siteButton.mintButton {
    margin-left: 0;
    font-size: 26px;
    height: 68px;
    width: 241px;
    font-weight: 700;
}
section.LandingSections {
    padding: 61px 0 420px;
}
.HeadingPattern.contentHeaderPattern .contentHeader {
    min-width: 370px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
}
.thumbnailCol img{
    width: 532px;
    height: 532px;
    object-fit: cover;
}
.contentGrid {
    display: flex;
    padding: 0 44px;
    grid-gap: 32px;
    gap: 32px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
    margin-top: -140px;
}
.contentCol p {
    font-size: 18px;
    color: #fff;
    margin: 30px 0 44px;
}
.contentCol button.siteButton.mintButton {
    margin-left: 0;
    font-size: 26px;
    height: 68px;
    width: 241px;
    font-weight: 700;
}
section.LandingSections {
    padding: 61px 0 420px;
}
.HeadingPattern.contentHeaderPattern .contentHeader {
    min-width: 370px;
}
.visitSetSec .HeadingPattern.contentHeaderPattern {
    justify-content: start;
    padding: 0 44px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
}
.thumbnailCol img{
    width: 532px;
    height: 532px;
    object-fit: cover;
}
.contentGrid {
    display: flex;
    padding: 0 44px;
    grid-gap: 32px;
    gap: 32px;
}
.thumbnailCol {
    border: 20px solid #000;
    width: 532px;
    height: 532px;
    margin-top: -140px;
}
.contentCol p {
    font-size: 18px;
    color: #fff;
    margin: 30px 0 44px;
}
.contentCol .siteButton.mintButton {
    margin-left: 0;
    font-size: 26px;
    height: 68px;
    width: 241px;
    font-weight: 700;
}
section.LandingSections {
    padding: 61px 0 420px;
}
@media (min-width:768px) and (max-width:1024px){
    .contentGrid {
        flex-direction: row;
        padding: 0 15px;
    }
    .thumbnailCol, .thumbnailCol img {
        width: 100%;
        height: auto;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern {
        padding: 15px 15px 0;
        height: 100px;
    }
    section.LandingSections.visitSetSec .HeadingPattern.contentHeaderPattern{
        justify-content: start;
        
    }
    .HeadingPattern.contentHeaderPattern .contentHeader {
        display: inherit;
        min-width: 50%;
    }
    .thumbnailCol {
        width: auto !important;
        margin: -95px auto 0;
        height: -webkit-max-content !important;
        height: max-content !important;
    }
    .contentCol p {
        margin: 0 0 30px;
    }
    section.LandingSections {
        padding: 60px 0 0px;
    }
    .LandingSections.visitSetSec .contentGrid {
        flex-direction: row;
    }
    .contentCol .siteButton.mintButton {
        margin-left: 0;
        font-size: 18px !important;
        height: 58px !important;
        width: 190px !important;
        font-weight: 700;
    }
    .contentCol {
        max-width: 40% !important;
    }
    
}
@media (max-width:767px){
    .contentGrid {
        flex-direction: column;
        padding: 0 15px;
    }
    .thumbnailCol, .thumbnailCol img {
        width: 100%;
        height: auto;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern .contentHeader {
        display: none;
    }
    section.LandingSections .HeadingPattern.contentHeaderPattern {
        padding: 0;
        justify-content: center;
    }
    section.LandingSections .HeadingPattern br {
        display: none;
    }
    .thumbnailCol {
        width: calc(100% - 42px);
        margin: -30px auto 0;
    }
    .contentCol p {
        margin: 0 0 30px;
    }
    section.LandingSections {
        padding: 60px 0 0px;
    }
    .LandingSections.visitSetSec .contentGrid {
        flex-direction: column-reverse;
    }
    .contentCol .siteButton.mintButton {
        margin-left: 0;
        font-size: 18px !important;
        height: 58px !important;
        width: 190px !important;
        font-weight: 700;
    }
    
}
.productBlock{
    padding: 12.64px;
    background: linear-gradient(135deg, rgba(254, 175, 32, 0) 0%, rgba(254, 175, 32, 0.3) 47.92%, rgba(254, 175, 32, 0) 100%);
}
.FlinchMerchContent h2 {
    text-align: center;
    color: #FEAF20;
    margin-bottom: 64px;
}
.productGrid {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 88px;
}
.productBlock img {
    width: 100%;
}
.FlinchMerchContent .siteButton.mintButton {
    font-size: 26px;
    width: 195px;
    height: 68px;
}
@media (min-width:768px) and (max-width:1024px){
    section.FlinchMerchSection {
        padding: 60px 0 0;
    }
    .FlinchMerchContent h2 {
        text-align: center;
        color: #FEAF20;
        margin-bottom: 20px;
    }
    .FlinchMerchContent .siteButton.mintButton {
        font-size: 20px;
        width: 165px;
        height: 58px;
    }
    section.FlinchMerchSection .productGrid {
        margin-bottom: 30px;
    }
}
@media (max-width:767px){
    section.FlinchMerchSection {
        padding: 60px 0 0;
    }
    section.FlinchMerchSection .productGrid {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .FlinchMerchContent h2 {
        text-align: center;
        color: #FEAF20;
        margin-bottom: 20px;
    }
    .FlinchMerchContent .siteButton.mintButton {
        font-size: 20px;
        width: 165px;
        height: 58px;
    }
}
section.TheCrewSection {
    padding: 250px 0 100px;
    text-align: center;
}
.TheCrewContent h2 {
    color: #FEAF20;
    margin-bottom: 64px;
}
.TheCrewGrid {
    display: flex;
    justify-content: center;
    grid-gap: 64px;
    gap: 64px;
    max-width: 778px;
    margin: 0 auto;
}
.CrewMember img {
    border-radius: 100%;
    margin-bottom: 48px;
}
.CrewMember span.crewDesignation {
    font-size: 24px;
    color: #feaf20;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}
.CrewMember h3 {
    color: #fff;
    font-size: 32px;
    margin: 8px 0 16px;
    line-height: 36px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}
.CrewMember p {
    color: #fff;
    max-width: 315px;
    margin: 0 auto;
}
.CrewMember ul.CrewSocial,
.CrewMember ul.CrewSocial li,
.CrewMember ul.CrewSocial li a {
    margin: 0;
    padding: 0;
    color: #FEAF20;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    font-size: 32px;
}
.CrewMember ul.CrewSocial li a{
    margin-top: 24px;
}
@media (min-width:768px) and (max-width:1024px){
    section.TheCrewSection {
        padding: 60px 0;
        text-align: center;
    }
    /* section.TheCrewSection .TheCrewGrid {
        flex-direction: column;
    } */
    .CrewMember img {
        border-radius: 100%;
        width: 200px;
        margin: 0 auto;
    }
    .CrewMember span.crewDesignation {
        width: 100%;
        display: inline-block;
        font-size: 18px;
        margin-top: 10px;
    }
    .CrewMember h3 {
        font-size: 24px;
        margin: 8px 0;
    }
}
@media (max-width:767px){
    section.TheCrewSection {
        padding: 60px 0;
        text-align: center;
    }
    section.TheCrewSection .TheCrewGrid {
        flex-direction: column;
    }
    .CrewMember img {
        border-radius: 100%;
        width: 200px;
        margin: 0 auto;
    }
    .CrewMember span.crewDesignation {
        width: 100%;
        display: inline-block;
        font-size: 18px;
        margin-top: 10px;
    }
    .CrewMember h3 {
        font-size: 24px;
        margin: 8px 0;
    }
}
.footerCols {
    display: flex;
    grid-gap: 192px;
    gap: 192px;
}
.footerCols .footerAbout {
    width: 470px;
    min-width: 470px;
    max-width: 470px;
}
.footerCols .footerSocial {
    width: 174px;
    min-width: 174px;
    max-width: 174px;
}
.footerCols .footerPages {
    width: 144px;
    min-width: 144px;
    max-width: 144px;
}
.footerSocial .socialList {
    flex-wrap: wrap;
    justify-content: start;
}
.footerCols h3 {
    margin: 0 0 24px;
    font-size: 24px;
    line-height: 26px;
    color: #FEAF20;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}
.footerCols .footerPages .socialConnect {
    padding: 0;
    border: none;
    background: transparent;
    font-size: 16px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 16px;
    display: inline-block;
    float: left;
    width: 100%;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
}
.footerSocial .socialList {
    display: inline-block;
    -webkit-column-count: 2;
            column-count: 2;
    max-width: 78px;
}
.footerSocial .socialList .socialConnect {
    display: inline-block;
    width: 100%;
    float: left;
    font-size: 28px;
    margin: 0px 16px 16px 0px;
    background-color: transparent;
}
.footerCols .footerAbout p {
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    margin: 24px 0 0;
}
footer.footer {
    padding: 100px 0;
    border-top: 1px solid rgb(255 255 255 / 12%);
    border-bottom: 1px solid #ffffff1f;
}
section.copyrightBar p, section.copyrightBar p a {
    margin: 0;
    color: #EEEEEE;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
}
section.copyrightBar {
    padding: 26px 0 20.5px;
}
section.copyrightBar p a:hover{
    color: #FEAF20;
}
@media (min-width:768px) and (max-width:1024px){
    footer.footer .footerCols {
        flex-wrap: wrap;
        flex-direction: row;
        grid-gap: 30px 30px;
        gap: 30px 30px;
    }
    .logoWrap a img {
        display: block;
        max-height: initial;
        width: 300px;
        margin: 0 auto;
    }
    .footerCols .footerAbout {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    footer.footer .footerCols .footerPages, footer.footer .footerCols .footerSocial {
        min-width: calc(50% - 15px);
        max-width: calc(50% - 15px);
        width: calc(50% - 15px);
        float: left;
        display: inline-block;
    }
    footer.footer {
        padding: 60px 0;
    }
    .footerCols h3 {
        font-size: 18px;
    }
    
}
@media (max-width:767px){
    footer.footer .footerCols {
        flex-wrap: wrap;
        flex-direction: row;
        grid-gap: 30px 30px;
        gap: 30px 30px;
    }
    .logoWrap a img {
        display: block;
        max-height: initial;
        width: 300px;
        margin: 0 auto;
    }
    .footerCols .footerAbout {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    footer.footer .footerCols .footerPages, footer.footer .footerCols .footerSocial {
        min-width: calc(50% - 15px);
        max-width: calc(50% - 15px);
        width: calc(50% - 15px);
        float: left;
        display: inline-block;
    }
    footer.footer {
        padding: 60px 0;
    }
    .footerCols h3 {
        font-size: 18px;
    }
    
}
.VisitTheSetSection{
    background: url(/static/media/visit.54a136f2.gif);
    background-size: cover;
    margin-top: 100px;
    display: inline-block;
    width: 100%;
}
.VisitTheSetHeading {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    grid-gap: 40px 0;
    grid-gap: 40px 0;
    gap: 40px 0;
    background: radial-gradient(139.76% 301.04% at -3.41% -2.35%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.79) 0.01%, rgba(0, 0, 0, 0.35) 100%);
    mix-blend-mode: normal;
    box-shadow: 0px 0px 19px 14px rgb(254 175 32 / 10%);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    margin: 0 auto;
    max-width: -webkit-max-content;
    max-width: max-content;
    padding: 50px;
    margin: 15% auto;
}
.VisitTheSetHeading h2 {
    font-size: 140px;
    line-height: 116px;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #865E17;
    color: #FEAF20;
    text-align: center;
    text-shadow: 0 0 50px #00000080;
}
.visitSetInfoPage {
    font-size: 14px;
    margin: 0;
    line-height: normal;
    color: #fff;
}
.disabledBtn{
    cursor: not-allowed;
}
@media (min-width:768px) and (max-width:1024px){
    .VisitTheSetHeading h2 {
        font-size: 82px;
        line-height: normal;
    }
    .VisitTheSetHeading {
        height: 500px;
    }
    .VisitTheSetSection {
        margin-top: 50px;
    }
}
@media (max-width:767px){
    .VisitTheSetHeading h2 {
        font-size: 52px;
        line-height: normal;
    }
    .VisitTheSetHeading {
        height: 500px;
    }
    .VisitTheSetSection {
        margin-top: 50px;
    }
}
.TheCinemaSection{
    margin-top: 40px;
    /* background: url('../../assets/imgs/cinema.jpg'); */
    background-size: cover;
    background-position: center center;
}
.cinemaContent .featureFilmHeader h2 {
    font-family: 'Futura';
    font-size: 32px;
    line-height: 36px;
    text-transform: unset;
    text-align: center;
}
.cinemaContent .featureFilmContent {
    max-width: 864px;
    margin: 0 auto;
}
.cinemaContent .featureFilmContent .filmMintArea .notice{
    max-width: 619px;
    margin-left: auto;
    margin-right: auto;
}
.TheCinemaSection .vimeoEmbed, .TheCinemaSection .vimeoEmbed div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width:768px) and (max-width:991px){
    section.TheCinemaSection .vimeoEmbed{
        height: 440px !important;
    }
}
@media (min-width:991px) and (max-width:1024px){
    section.TheCinemaSection .vimeoEmbed{
        height: 660px !important;
    }
}
@media (max-width:767px){
    section.TheCinemaSection .vimeoEmbed{
        height: 300px !important;
    }
}
section.mintingContent {
    padding: 100px 0;
    background: #00BBBC;
    text-align: center;
    color: #fff;
    margin-top: 100px;
}
.mintingContentArea h2 {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 700;
}
.mintingContentArea p {
    margin-bottom: 0;
    margin-top: 16px;
}
.mintCalcSection{
    background-image: url(/static/media/genesispass.22fc3428.jpg);
    background-size: cover;
    background-position: center;
    padding: 176px 0;
}
.mintingBlock {
    display: flex;
    flex-wrap: wrap;
    max-width: 703px;
    background: radial-gradient(139.76% 301.04% at -3.41% -2.35%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.79) 0.01%, rgba(0, 0, 0, 0.35) 100%);
    mix-blend-mode: normal;
    box-shadow: 0px 0px 19px 14px rgb(254 175 32 / 10%);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    margin: 0 auto;
    margin-left: 149px;
}
.mintingBlock .mintThumb {
    margin: 125px 0 auto -149px;
    max-width: 304px;
    max-height: 304px;
}
.mintingBlock .mintThumb img{
    width: 100%;
}
.mintingBlock .mintCalculater {
    max-width: 360px;
    min-width: 360px;
    margin-left: 31px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mintingBlock .mintCalculater h2 {
    color: #FEAF20;
    margin-bottom: 49px;
    font-family: "Futura";
    font-style: normal;
    font-size: 38px;
    line-height: 36px;
}
form.FormMinting .fieldWrap {
    display: flex;
    max-width: 196px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}
form.FormMinting .fieldWrap button.quantityButton {
    border: 3px solid #FEAF20;
    background: #feaf2033;
    color: #FEAF20;
    width: 42px;
    height: 42px;
    font-size: 30px;
    padding: 0;
    line-height: 0;
    cursor: pointer;
}
form.FormMinting .fieldWrap input, form.FormMinting .fieldWrap input:focus {
    background: transparent;
    border: none;
    text-align: center;
    font-size: 32px;
    width: 100%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    outline: none;
}
form.FormMinting .fieldWrap ~ span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 49px;
    display: inline-block;
}
.mintingBlock .mintCalculater form.FormMinting {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.totalCost {
    border-top: 2px solid #fff3;
    border-bottom: 2px solid #fff3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 49px;
    grid-gap: 0px 22px;
    gap: 0px 22px;
}
.totalCost > span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 14px 0;
    text-align: center;
}
/* .totalCost h3 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
    color: #FEAF20;
    margin: 14px 0;
}
.totalCost h3 span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.5;
    margin-left: 9px;
    position: relative;
    top: -4px;
} */
.DescriptionMinting {
    max-width: 651px;
    margin: 50px auto 0;
}
.DescriptionMinting h4 {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    color: #27FCFA;
    margin: 0;
}
.DescriptionMinting p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 8px 0 32px;
}
.contractAddress {
    border-top: 2px solid #fff3;
    width: 100%;
    padding: 26px 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-transform: unset;
    font-weight: 600;
}
.contractAddress span{
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FEAF20;
}
.mintingBlock.rightAligned {
    margin-right: 0;
    margin-left: auto;
}
.mintCalcSection.CharacterMintSection{
    background-image: url(/static/media/characterMintBg.3577dd5b.jpg);
}
.mintCalcSection.WeaponMintSection{
    background-image: url(/static/media/weaponMintBg.28e5fa45.jpg);
}
.extraWidthBtn{
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.buttonGlowing {
  -webkit-animation: 2s ease-in-out fadeIn infinite;
  animation: 2s ease-in-out fadeIn infinite;
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #FEAF20;
    -webkit-filter: drop-shadow(0px 0px 10px #FEAF20);
            filter: drop-shadow(0px 0px 10px #FEAF20);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    background: #FEAF20;
    -webkit-filter: drop-shadow(0px 0px 10px #FEAF20);
            filter: drop-shadow(0px 0px 10px #FEAF20);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.mintCalcSection.WeaponMintSection {
    padding: 25% 0;
}
.mintCalcSection.WeaponMintSection a.siteButton.extraWidthBtn {
    max-width: 560px;
}
.mintCalcSection.WeaponMintSection a.siteButton.extraWidthBtn:not(:hover) {
    max-width: 560px;
    background: #00000061;
}
@media (min-width:768px) and (max-width:1024px){
    .mintCalculater {
        width: calc(100% - 235px);
        text-align: center;
        max-width: 100% !important;
    }
    .DescriptionMinting {
        max-width: 550px;
        margin: 50px auto 0;
    }
    .mintingBlock .mintCalculater h2 {
        font-size: 32px;
        margin-bottom: 30px;
    }
    .mintingBlock .mintThumb {
        margin: 70px 0 auto -100px;
        max-width: 304px;
        max-height: 304px;
    }
    .mintingBlock {
        max-width: 580px !important;
        margin-left: 100px;
    }
    .mintingBlock.rightAligned {
        margin-right: 0;
        margin-left: auto;
        max-width: 600px;
    }
    .mintCalcSection{
        padding: 70px 0;
    }
    section.mintingContent {
        margin-top: 50px;
        padding: 70px 0;
    }

}
@media (max-width:767px){
    section.mintingContent {
        padding: 50px 0;
        margin-top: 50px;
    }
    .mintCalcSection {
        padding: 120px 0 60px;
    }
    .mintCalcSection .mintingBlock {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }
    .contractAddress span {
        width: 100%;
        display: inline-block;
    }
    .contractAddress {
        word-break: break-all;
        padding: 15px;
        margin: 0;
        display: inline-block;
        width: -webkit-fill-available;
    }
    .mintingBlock .mintThumb {
        margin: -50px 0 30px;
        max-width: 100%;
        max-height: initial;
        padding: 0;
        width: 100%;
    }
    .mintingBlock .mintThumb img {
        width: calc(100% - 20px);
        margin: 0 auto;
        display: block;
    }
    .mintCalculater {
        width: 100%;
        margin: 0 !important;
        text-align: center;
        max-width: 100% !important;
        min-width: 100% !important;
    }
    .DescriptionMinting {
        max-width: 100%;
        margin: 30px 0 0;
        padding: 0 15px;
        width: auto !important;
    }
    .mintingBlock .mintCalculater h2 {
        font-size: 28px;
        margin-bottom: 30px;
    }
    form.FormMinting .fieldWrap ~ span {
        margin-bottom: 30px;
    }
    .totalCost {
        margin-bottom: 30px;
    }
    .extraWidthBtn {
        width: auto;
        padding: 10px;
        max-width: 230px !important;
        line-height: 23px;
    }
}
.comingSoonSection{
    background: url(/static/media/visit.54a136f2.gif);
    background-size: cover;
    margin-top: 100px;
}
.comingHeading{
    height: 952px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.comingHeading h2 {
    font-size: 140px;
    line-height: 116px;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #865E17;
    color: #FEAF20;
    text-align: center;
    text-shadow: 0 0 50px #00000080;
}
@media (min-width:768px) and (max-width:1024px){
    .comingHeading h2 {
        font-size: 82px;
        line-height: normal;
    }
    .comingHeading {
        height: 500px;
    }
    .comingSoonSection {
        margin-top: 50px;
    }
}
@media (max-width:767px){
    .comingHeading h2 {
        font-size: 52px;
        line-height: normal;
    }
    .comingHeading {
        height: 500px;
    }
    .comingSoonSection {
        margin-top: 50px;
    }
}
