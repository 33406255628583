.headerWrap {
    display: flex;
    justify-content: space-between;
}
.headerWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    border-bottom: 2px solid #fff3;
}
.logoWrap a {
    display: inline-block;
    width: 100%;
}
.logoWrap a img {
    max-height: 32px;
}
.siteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 217px;
    height: 60px;
    border: 3px solid #FEAF20;
    filter: drop-shadow(0px 0px 24px #FEAF20);
    border-radius: 8px;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    text-transform: uppercase;
    background: transparent;
    color: #fff;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: "Futura";
    margin: 0 auto;
    text-decoration: none;
}
.siteButton:hover, .siteButton:active {
    background: #FEAF20;
    -webkit-filter: drop-shadow(0px 0px 10px #FEAF20);
    filter: drop-shadow(0px 0px 10px #FEAF20);
}
.siteButton.mintButton {
    border: 3px solid #27fcfa;
    filter: drop-shadow(0px 0px 24px #27fcfa);
    width: 186px;
    padding: 0;
}
.siteButton.mintButton:hover,
.siteButton.mintButton:active{
    background: #27fcfa;
    -webkit-filter: drop-shadow(0px 0px 10px #27fcfa);
    filter: drop-shadow(0px 0px 10px #27fcfa);
}
.navWrap button {
    background: transparent;
    color: #FEAF20;
    font-size: 28px;
    border: none;
    cursor: pointer;
    padding: 0;
}
.headerWrap .navWrap, .headerWrap .ctaWrap {
    min-width: 217px;
}
.navWrap button:hover {
    color: #fff;
}
.socialList {
    justify-content: center;
    display: flex;
    align-items: center;
    grid-gap: 22px;
    gap: 22px;
    margin-top: 20px;
}
.socialList .socialConnect {
    background: transparent;
    border: none;
    color: #27FCFA;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 30px;
    width: 28px;
    cursor: pointer;
    position: relative;
}
.titleSocial {
    position: absolute;
    right: 23px;
    font-size: 11px;
    bottom: 14px;
    border: 1px solid #27FCFA;
    width: 13px;
    height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 100%;
    color: #000;
    background: #27FCFA;
    font-weight: 800;
}
.slideMenu {
    background: #0F0F0F;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999;
    max-width: 290px;
    padding: 60px 27px 0 37px;
    width: 100%;
    left: -354px;
    transition: all 0.4s linear;
    opacity: 0;
    border-right: 2px solid #fff3;
}
.menuActiveOverlay:before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background: #0000006e;
    left: 0;
    top: 0;
    z-index: 9;
}
.slideMenu.menuActive{
    left: 0;
    opacity: 1;
}
ul.SideNavigation {
    padding: 52px 0;
    margin: 28px 0;
    border-top: 2px solid #fff3;
    border-bottom: 2px solid #fff3;
}
ul.SideNavigation li a.socialConnect {
    color: #FEAF20;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    position: relative;
}
ul.SideNavigation li a.socialConnect:after {
    content: '';
    width: 0;
    height: 2px;
    background-color: #feaf20cc;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    clear: both;
    position: absolute;
    bottom: 0;
    left: 0;
}
ul.SideNavigation li a.socialConnect:hover:after,
ul.SideNavigation li a.socialConnect:visited:after{
    width: 50px;
}
ul.SideNavigation li:not(:last-child) a.socialConnect {
    margin-bottom: 56px;
}
.slideMenu .navWrap {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: visible;
    padding-bottom: 150px;
}
.slideMenu .navWrap .socialList {
    flex-wrap: wrap;
    justify-content: start;
    grid-gap: 26px 27px;
    gap: 26px 27px;
}

.slideMenu .navWrap .socialList span.titleSocial {
    right: -10px;
}
@media (min-width:768px) and (max-width:1024px){
    .headerWrap .ctaWrap, .headerWrap .navWrap {
        min-width: unset;
    }
    .headerWrap {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px 0px;
        position: relative;
    }
    .headerWrap .navWrap {
        order: 1;
    }
    .headerWrap .ctaWrap {
        order: 2;
        margin-left: 25px;
    }
    .headerWrap .logoWrap {
        width: auto;
        text-align: left;
        margin: 0 auto 0 0;
    }
    .headerWrap .logoWrap img {
        width: 100%;
        max-height: 32px;
        width: auto;
    }
    .headerWrap {
        flex-wrap: nowrap;
        justify-content: center;
        gap: 30px 0px;
        position: relative;
    }
    ul.SideNavigation li:not(:last-child) a.socialConnect {
        margin-bottom: 20px;
    }
    ul.SideNavigation {
        padding: 35px 0;
        margin: 25px 0;
        border-top: 2px solid #fff3;
        border-bottom: 2px solid #fff3;
    }
}
@media (max-width:767px){
    .headerWrap .ctaWrap, .headerWrap .navWrap {
        min-width: unset;
    }
    .headerWrap {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px 0px;
        position: relative;
    }
    .headerWrap .navWrap {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        left: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        margin-top: 40px;
    }
    .headerWrap .logoWrap {
        width: calc(100% - 100px);
        text-align: center;
        margin: 0 auto;
    }
    .logoWrap a img {
        max-height: 32px;
        width: 100%;
    }
    .headerWrap .logoWrap img {
        width: 100%;
    }
    ul.SideNavigation li:not(:last-child) a.socialConnect {
        margin-bottom: 10px;
    }
    ul.SideNavigation {
        padding: 25px 0;
        margin: 15px 0;
        border-top: 2px solid #fff3;
        border-bottom: 2px solid #fff3;
    }
}