.comingSoonSection{
    background: url('../../assets/imgs/visit.gif');
    background-size: cover;
    margin-top: 100px;
}
.comingHeading{
    height: 952px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.comingHeading h2 {
    font-size: 140px;
    line-height: 116px;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: #865E17;
    color: #FEAF20;
    text-align: center;
    text-shadow: 0 0 50px #00000080;
}
@media (min-width:768px) and (max-width:1024px){
    .comingHeading h2 {
        font-size: 82px;
        line-height: normal;
    }
    .comingHeading {
        height: 500px;
    }
    .comingSoonSection {
        margin-top: 50px;
    }
}
@media (max-width:767px){
    .comingHeading h2 {
        font-size: 52px;
        line-height: normal;
    }
    .comingHeading {
        height: 500px;
    }
    .comingSoonSection {
        margin-top: 50px;
    }
}