.TheCinemaSection{
    margin-top: 40px;
    /* background: url('../../assets/imgs/cinema.jpg'); */
    background-size: cover;
    background-position: center center;
}
.cinemaContent .featureFilmHeader h2 {
    font-family: 'Futura';
    font-size: 32px;
    line-height: 36px;
    text-transform: unset;
    text-align: center;
}
.cinemaContent .featureFilmContent {
    max-width: 864px;
    margin: 0 auto;
}
.cinemaContent .featureFilmContent .filmMintArea .notice{
    max-width: 619px;
    margin-left: auto;
    margin-right: auto;
}
.TheCinemaSection .vimeoEmbed, .TheCinemaSection .vimeoEmbed div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width:768px) and (max-width:991px){
    section.TheCinemaSection .vimeoEmbed{
        height: 440px !important;
    }
}
@media (min-width:991px) and (max-width:1024px){
    section.TheCinemaSection .vimeoEmbed{
        height: 660px !important;
    }
}
@media (max-width:767px){
    section.TheCinemaSection .vimeoEmbed{
        height: 300px !important;
    }
}